//Default Buttons
.curved-btn, .rect-btn, .cta-btn, .big-btn{
	text-decoration: none !important;
	transition: all .15s ease-in-out;
}
.curved-btn{
	border-radius: 50px;
    color: #fff;
    background-color: transparent;    
    padding: 10px 70px;      
    &:hover{
    	cursor: pointer;
    }
}
.rect-btn{
	padding: 12px 12px;
    font-weight: 300;
    border-radius: $border-width;
    border-style: none;     
    &:hover{
    	cursor: pointer;
    }
}
.cta-btn{
    text-shadow: none;
     padding: .5em 25px;
    line-height: 2em;
    border-radius: $border-width;
    &:hover{
    	cursor: pointer;
    }
}
.big-btn{
	text-shadow: none;     
    line-height: 2em;
	padding: 20px 40px;
    border-radius: 0px;
    font-size: 14px;
    letter-spacing: 2px;
    font-weight: bold;
    border: 3px solid transparent;
}
.small-btn.btn-1-outline, .small-btn.btn-2-outline, .small-btn.btn-3-outline{
	padding: 7px 17px;
}
.small-btn{
	text-shadow: none;
    line-height: 2em;
    padding: 10px 20px;
    border-radius: 0;
    font-size: 12px;
    letter-spacing: 2px;
    font-weight: 700;
    margin-bottom: 5px;
    text-align: center;
    width: 180px;
}

.super-small-btn{
	text-shadow: none;
    line-height: 2em;
    border-radius: 0;
    font-weight: 700;
    margin-bottom: 5px;
    text-align: center;
	font-size: 10px;
    letter-spacing: 1px;
    padding: 6px 0;
    display: block !important;
}

// Font sizes
.font-1{
	font-size: ms(-1);
}
.font0{
	font-size: ms(0);
}
.font1{
	font-size: ms(1);
}
.font2{
	font-size: ms(2);
}


.btn-1-hover{
	background-color: $color-1;
	color: white !important;
	transition: all .15s ease-in-out;
	&:hover{
		background-color: $color-2;
		color: white !important;
	}
}
.btn-2-hover{
	background-color: $color-2;
	color: white !important;
	transition: all .15s ease-in-out;
	&:hover{
		background-color: $color-3;
		color: white !important;
	}
} 

.btn-3-hover{
	background-color: $color-3;
	color: white !important;
	transition: all .15s ease-in-out;
	&:hover{
		background-color: $color-2;
		color: white !important;
	}
} 

.btn-1-outline{
	border: $border-width solid $color-1;
	background-color: transparent;
	color: $color-1;
	transition: all .15s ease-in-out;
	&:hover{
		color: white;
		background-color: $color-1;
	}
} 
.btn-2-outline{
	border: $border-width solid $color-2;
	background-color: transparent;
	color: $color-2;
	transition: all .15s ease-in-out;
	&:hover{
		color: white;
		background-color: $color-2;
	}
} 

.btn-3-outline{
	border: $border-width solid $color-3;
	background-color: transparent;
	color: $color-3;
	transition: all .15s ease-in-out;
	&:hover{
		color: white;
		background-color: $color-3;
	}
} 

.btn-4-outline{
	border: $border-width solid $color-4;
	background-color: transparent;
	color: $color-4;
	transition: all .15s ease-in-out;
	&:hover{
		color: white;
		background-color: $color-4;
	}
} 

.btn-5-outline{
	border: $border-width solid $color-5;
	background-color: transparent;
	color: $color-5;
	transition: all .15s ease-in-out;
	&:hover{
		color: white;
		background-color: $color-5;
	}
} 

.f100{
	width: 100% !important;
}

.line-btn{
    border: none;
    background: none;
    padding: 20px 80px 30px 0;
    overflow: initial;
    font-family: inherit;
    font-size: 12px;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    outline: 0;
    position: relative;
    overflow: visible;
    display: inline-block;
    color: $color-1;
    &:before {
	    font-family: 'FontAwesome';
	    speak: none;
	    font-style: normal;
	    font-weight: 400;
	    font-variant: normal;
	    text-transform: none;
	    line-height: 1;
	    position: relative;
	    -webkit-font-smoothing: antialiased;
	    content: "\f178;";
	    position: absolute;
	    height: 100%;
	    font-size: 125%;
	    line-height: 3.5;
	    color: $color-1;
	    right: 10px;
	    top: 0;
	    transition: all .3s;
	}
    &:after {
	    content: "";
	    position: absolute;
	    z-index: 0;
	    height: 2px;
	    left: 0;
	    right: 0;
	    display: inline-block;
	    top: 45px;
	    background-color: $color-1;
	}
}

.top-nav-btn{
	    margin: 24px 40px 0 0;
    border: 2px solid #fff;
    background-color: transparent;
    border-radius: 0;
    transition: all .15s ease-in-out;
    letter-spacing: 1px;
    color: #fff;
    vertical-align: middle;
}

.question-btn{
	font-size: 28px;
    color: white;
    padding-left: 5px;
    display: inline-block;
    vertical-align: middle;
}

.question-btn-invert{
	font-size: 28px;
    color: $color-1;
    padding-left: 5px;
    display: inline-block;
    vertical-align: middle;
}