// Grid settings
$gridXtralarge: 1400px;
$gridlarge: 1340px;
$gridlargeHalf: 585px;
$gridMedium: 960px;
$gridSmall: 640px;

// Breakpoints
$xlarge:  1200px;
$large:   960px;
$medium:  768px;
$small:   600px;
$mini:    470px;

// Colors
$color-1:			#4484CE;
$color-2:			#2f3339;
$color-3:			#77c04b;
$color-4:			#F19F4D;
$color-5:			#0b3c5d;

$pattern-1:			'/wp-content/uploads/2017/08/seigaiha.png';

$grey-1:			#F9F6F2;
$grey-2:			#EDEDED;
$grey-3:			#f6f9fb;

$grey-text:			#999999;

$spacer:			30px;

// Navbar heights
$full-nav-height:	80px;
$mobile-nav-height:	66px;


$border-width:		3px;