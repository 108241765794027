ul.post-categories{ 
	list-style-type: none; padding-left: 0; 
	li{ display: inline-block; 
		a{ padding: 5px 8px; background: $color-1; color: white !important; margin-right: 5px; } 
		&:last-child{ margin-right: 0; } 
	} 
}

.listings-container{
	font-size: 14px;
    border-bottom: 1px solid #f3f7fa;
    padding: 15px 20px;
    color: #868da0;
    transition: .25s all ease-in-out;
    &:hover {
    	box-shadow: 0 5px 20px rgba(0,0,0,0.05);
	}	
}
.listing-type-circle{
	border-radius: 50%;
	border: 1px solid #DBE2E8;
	padding-bottom: 100%;
	background-image: url('/wp-content/uploads/2017/10/grey_house.png');		
    background-position: center center;
	background-size: 60%;
	background-repeat: no-repeat;
}
.treatment-type{
    background-image: url('/wp-content/uploads/2017/10/grey_house.png');        
}
.doctor-type{
    background-image: url('/wp-content/uploads/2017/10/grey_doctor.png');
}

h3.details-heading{
    font-weight: bold;
    margin: 0;
}
.single-listing-map{
    height: 0;
    padding-bottom: 60%;
    // border: 2px solid #012951;
    width: 100%;
    position: relative;
}

.single-listing-map iframe{
    border: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.page_title{
    overflow: visible;
    padding: 0 15px;
    margin-bottom: 20px;
}

.page_title h1{
    text-align: left;
    color: #012951;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.white-row, .grey-row{
    font-size: 16px;
    line-height: 28px;
    position: relative;
    padding: 10px 15px 10px 60px;
}
.grey-row{
    background-color: #eee;    
}

.row-container{

}
.inner-row{
    font-size: 16px;
    line-height: 28px;
    position: relative;
    padding: 10px 15px 10px 60px;
    position: relative;
}
.inner-row:nth-child(2n){
    background-color: #eee;
}
.inner-row:first-child:before{
    content: '';
}
.inner-row:first-child{
        padding: 20px;

}
.inner-row:before{
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #3d5b95;
    font-size: 24px;
    padding-right: 0.5em;
    position: absolute;
    left: 20px;
    content: '\f046';
}

.details-box{
    border: 2px solid black;
    border-bottom: none;
}

.details-box:last-child{
    border-bottom: 2px solid black;
}
.website-row:before, .phone-row:before, .address-row:before, .info-row:before {    
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: $color-1;
    font-size: 24px;
    line-height: 40px;
    padding-right: 0.5em;
    position: absolute;
    /* top: 20px; */
    left: -40px;
}
.phone-row:before{ content: "\f095";}
.website-row:before{ content: "\f109";}
.address-row:before{ content: "\f015";}
.info-row:before{ content: "\f129"; }

.info-box-padding{
    padding: 25px 25px 30px;
    p{
        line-height: 1.5em;
    }
}
.phone-row, .address-row, .website-row{
    margin-left: 50px;
}
// .info-row:before {    
//     font-family: FontAwesome;
//     font-style: normal;
//     font-weight: normal;
//     text-decoration: inherit;
//     color: $color-1;
//     font-size: 24px;
//     line-height: 40px;
//     padding-right: 0.5em;
//     position: absolute;
//     top: 20px; 
//     left: 20px;
// }

h2.subheading{
    text-align: left;
    color: #3d5b95;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 24px;
    padding-top: 20px;
    text-shadow: none;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.small-text{
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    color: black;
    display: block;
    text-align: center;
    padding: 5px 0;
}