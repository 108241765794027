.gridl, .gridm, .grids, .gridlh, .gridnav{
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.gridnav{
  max-width: $gridXtralarge;
}

.gridl{
  max-width: $gridlarge;
}

.gridlh{
  margin: 0;
  max-width: $gridlargeHalf;
}

.gridm{
  max-width: $gridMedium;
}

.grids{
  max-width: $gridSmall;
}