/* Mixins */

// Responsive and simple breakpoint mixin
@mixin breakpoint($point) {
   @if $point == ws {
     @media (min-width: $xlarge) { @content ; }
    }
   @else if $point == dk {
     @media (min-width: $large) { @content ; }
  }
    @else if $point == tb {
     @media (min-width: $medium) { @content ; }
  }
   @else if $point == ph {
     @media (min-width: $small)  { @content ; }
  }
  @else if $point == mo {
     @media (max-width: $mini)  { @content ; }

  }
  @else if $point == nv {
     @media (min-width: 1220px)  { @content ; }

  }
}