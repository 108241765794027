$base-size: 16px;
$ms-ratio: 1.25;


h1{
	font-size: ms(4);
	line-height: 1.2em;
}

h2{
	font-size: ms(3);
	line-height: 1.2em;
}

h3{
	font-size: ms(2);
	line-height: 1.5;	
}

h4{
	font-size: ms(1);
	line-height: 1.2em;
}

h5{
	font-size: ms(0);
	line-height: 1.2em;
	font-weight: bold;
}

p.large, li.large{
	font-size: ms(1);
	font-weight: 300;
}

p, a, span{
	font-size: ms(0);
	line-height: 1.714285714;
}

html{
	font-size: 16px;
}

a{
	text-decoration: none !important;
	transition: color .15s ease-in-out;
	color: $color-1;
	&:hover{
		color: $color-2;
	}
}


h1, h2, h3, h4, h5, h6, a, span{
	font-family: "Open Sans", sans-serif;
	font-weight: 300;
}

a{
	font-family: "Open Sans", sans-serif;
}

html, body, p{
	font-family: "Open Sans", sans-serif;
	line-height: 1.8;
}

.lh-2{
	line-height: 2em !important;
}

.breadcrumbs-container{
	z-index: 2222;
	background: white;
	border-bottom: 1px solid #e9eff5;
	padding: 10px 0;
	height: 49px;
	// Breadcrumbs
	p#breadcrumbs{ 
		padding: 0;
	    margin: 0;
	    transition: all .25s ease-in-out;
	}
	p#breadcrumbs a{ color: $color-1; }
}
.breadcrumbs-container.uk-active #breadcrumbs{ position: absolute; left: 10px; }


// Icon List
.fa-ul {
    // padding-left: 0;
    // margin-left: 2.14285714em;
    // list-style-type: none;
    .fa-li {
	    position: relative;
	    // left: -2.14285714em;
	    left: 0;
	    width: 2.14285714em;
	    top: 0.14285714em;
	    text-align: center;
	    display: inline-block;
	}
}


//Accordian Stuff
.uk-accordion-title{
	// color: white;
	// background-color: $color-2;
	transition: all .25s ease-in-out;
	background-color: transparent;
}

.uk-accordion-title.uk-active{
	// background-color: $color-1;	
}
h3.uk-accordion-title{
	&:nth-last-child(2){
		border-bottom: 1px solid #222;
	}
    position: relative;
    padding: 15px;
    padding-right: 42px;
    border-top: 1px solid #222;
    margin-bottom: 0;
}

h3.uk-accordion-title:after{
    content: "+";
    display: block;
    height: 30px;
    position: absolute;
    right: 7px;
    font-weight: 700;
    font-size: 30px;
    width: 30px;
    text-align: center;
    top: 50%;
    margin-top: -12px;
}
h3.uk-active.uk-accordion-title:after{
    content: '-';
}

.line-under, .line-under-center{
	position: relative;
    padding-bottom: 30px;
	&:after{
		width: 40%;
		content: '';
		height: $border-width;
		margin: 0 auto;
		background-color: $color-3;
	    position: relative;
	    top: 15px;
	    min-width: 80px;
	    display: block;
	    @include breakpoint( tb ){
	    	margin: 0;
	    }  
	}
}

.line-under-left{
	&:after{
		@include breakpoint( tb ){
			left: 60%;
		}
	}
}

.line-under-center{
	&:after{
		margin: 0 auto !important;
		max-width: 120px;
	}
}


.txt-shadow{
	text-shadow: 0px 0px 5px rgba(0,0,0,.6);
}

.heavy{
	font-weight: 700;
}

.light{
	font-weight: 300;
}

h1.heavy-header{
	font-size: ms(5);
	font-weight: 700;
}

.line-above{
	position: relative;
	&:before{
	    content: "";
	    display: block;
	    margin: 0 auto 1em;
	    height: 2px;
	    width: 160px;
	    background: #2196f3;
	}
}

.line-above-left{
	position: relative;
	&:before{
	    content: "";
	    display: block;
	    margin: 0 0 1em;
	    height: 2px;
	    width: 160px;
	    background: #2196f3;
	}
}

.menu-link{ font-size: 14px; }