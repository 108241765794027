.sidebar{ }

.top-bar-border{ border-top: 5px solid $color-1; background-color: white; padding: 20px 15px; }

.widget {
    margin-bottom: 30px!important;
    border: 1px solid #e5e5e5;
    h3 {
	    letter-spacing: 1px;
	    color: white;
	    padding: 12px;
	    background: $color-2;
	}
}
// main ul{
// 	list-style: none;
// 	li{
// 		position: relative;
// 		:before{
// 			content: "\f00c";
// 		    font-family: FontAwesome;
// 		    display: inline-block;
// 		    font-weight: 400;
// 		    font-style: normal;
// 		    padding-right: 5px;
// 		    line-height: 1;
// 		    -webkit-font-smoothing: antialiased;
// 		    color: $color-2;
// 		    position: absolute;
// 		    left: -20px;
// 		    line-height: 1.8em;
// 		}
// 	}
// }

section.widget_recent_entries ul, ul.custom-list {
    list-style: none;
    padding: 0 20px;
    li {
	    padding: 5px 0;
	    border-bottom: 1px solid #e5e5e5;
	    a{
	    	text-decoration: none!important;
		    transition: color .15s ease-in-out;
		    color: $color-2;
	    }
	    &:before {
		    content: "\f249";
		    font-family: FontAwesome;
		    display: inline-block;
		    font-weight: 400;
		    font-style: normal;
		    padding-right: 5px;
		    line-height: 1;
		    -webkit-font-smoothing: antialiased;
		    color: $color-1;
		}
	    &:last-child {
	 	   border-bottom: none;
		}
	}
}

section.widget ul, ul.custom-list {
    list-style: none;
    padding: 0 20px;
    li {
	    padding: 5px 0;
	    border-bottom: 1px solid #e5e5e5;
	    a{
	    	text-decoration: none!important;
		    transition: color .15s ease-in-out;
		    color: $color-1;
	    }
	    &:before {
		    content: "\f00c";
		    font-family: FontAwesome;
		    display: inline-block;
		    font-weight: 400;
		    font-style: normal;
		    padding-right: 5px;
		    line-height: 1;
		    -webkit-font-smoothing: antialiased;
		    color: $color-1;
		}
	    &:last-child {
	 	   border-bottom: none;
		}
	}
}

@media screen and (min-width: 768px ){
	.sticky-ad{
		position: -webkit-sticky;  // required for Safari
    	position: sticky;
    	top: 160px;
	}
}