// // Search form
// .search-form {
//   @extend .form-inline;
// }
// .search-form label {
//   font-weight: normal;
//   @extend .form-group;
// }
// .search-form .search-field {
//   @extend .form-control;
// }
// .search-form .search-submit {
//   @extend .btn;
//   @extend .btn-secondary;
// }

input, textarea, select{ width: 100%; }

input.bg-input{
background: rgba(255,255,255, .9) !important;
  height: 36px !important;
}

 textarea.bg-input{
background: rgba(255,255,255, .9) !important;
}
input.bg-input:focus, textarea.bg-input:focus{
background:white !important;
  border: 2px solid #ffb600 !important;
}

.yellow-phone-btn{
  background-color: #ffb600 !important;
  transition: all .15s ease-in-out;
}
.yellow-phone-btn:hover{
  opacity: .7;
}