header.banner {
    overflow: hidden;
    top: 0;
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 9999999;
    right: 0;
    min-height: $mobile-nav-height;
    box-shadow: 0 0 2px #333;
    padding: 0 20px;
    @include breakpoint( tb ){
    	min-height: $full-nav-height;
    	padding: 0 10px;
    }
    a.brand{
        display: inline-block;
    	vertical-align: middle;
    	overflow: hidden;
    	width: 50px !important;
        z-index: 1;
        position: relative;
        padding: 22px 0 18px;
    	@include breakpoint( tb ){
    		padding: 22px 0 18px;
    		width: 300px !important;
    	}
    	img{
    		max-width: 280px;
    	}
    }
    nav.nav-primary{
        display: none;
        @include breakpoint( ws ){
            display: block;
        }
    	ul.nav{
    		padding-left: 0;
    		list-style: none;
    		margin-bottom: 0;
    		li{
    			display: inline-block;
    			margin-right: 40px;
    			a{
    			    color: #fff;
				    line-height: 80px;
				    font-weight: 300;
    			}    			    		
    		}
    	}
    }
    .mat-phone-nav{
        margin-top: 30px;
        float: none;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        text-align: center;
        right: 0;
        z-index: 0;
        &:before{
            content: "Need Help? Call Now!";
            color: white;
            position: absolute;
            text-align: center;
            width: 100%;
            left: 0;
            right: 0;
            top: -30px;
        }
        .top-nav-btn{ margin-right: 0 !important; }
        @include breakpoint( ws ){
            position: relative;
            float: right;
            width: auto;
            text-align: left;
            margin-top: 24px;
            // .top-nav-btn{ margin-right: 40px !important; }
            &:before{
                content: '';
                display: none;
            }
        }
    }
    .nav-responsive-menu{
        float: right;
        font-size: 32px;
        padding: 11px;
        z-index: 1;
        position: relative;
        @include breakpoint( ws ){
            display: none;
        }
    }
}