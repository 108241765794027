body{
    margin-top: $mobile-nav-height  !important;
    @include breakpoint ( tb ){
	   margin-top: $full-nav-height !important;
    }
}

// Border-box all the way!
*{
    box-sizing: border-box;
    -webkit-appearance: none;
}

// Backgrounds
.bg-1{
	background-color: $color-1;
}
.bg-2{
    background-color: $color-2;
}
.bg-3{
    background-color: $color-3;
}
.bg-4{
    background-color: $color-4;
}
.bg-5{
    background-color: $color-5;
}
.bg-white{
	background-color: white;
}
.bg-g1{
    background-color: $grey-1;
}
.bg-g2{
    background-color: $grey-2;
}
.bg-g3{
    background-color: $grey-3;
}

.pattern-1{
    background-image: url($pattern-1);
}

.bx-shadow-4 {
    box-shadow: 4px 4px 12px rgba(0,0,0,.6);
}
.border-frame-left, .border-frame-right {
    position: absolute;
    height: 100%;
    width: 100%;
    // max-width: 500px;
    top: 20px;
    border: 3px solid $color-1;
    z-index: 1;
}
.border-frame-right {
    right: -20px;
}
.border-frame-left {
    left: -20px;
}


// Font colors
.color-1{
    color: $color-1;
}
.color-2{
    color: $color-2;
}
.color-3{
    color: $color-3;
}
.color-4{
    color: $color-4;
}
.color-5{
    color: $color-5;
}
.color-g1{
    color: $grey-1;
}
.color-g2{
    color: $grey-2;
}
.color-g3{
    color: $grey-3;
}
.color-g{
    color: $grey-text;
}
.color-white{
    color: white;
}


// Text padding
.text-padding{
    padding-left: 20px;
    padding-right: 20px;
    @include breakpoint( tb ){
        padding-left: 25px;
        padding-right: 25px;
    }
}

.small-padding{
    padding-top: 20px;
    padding-bottom: 20px;
    @include breakpoint( tb ){
        padding-top: 25px;
        padding-bottom: 25px;
    }
}


// Class for overlay
.overlay{
    position: relative;
    z-index: 1;
    transition: all .25s ease-in-out;
    &:before{
        z-index: -1;
        content: '';        
        position: absolute;
        display: block;
        top: 0; right: 0; bottom: 0; left: 0;
        width: 100%; height: 100%;    
    }
}

.dark-overlay{
    &:before{
        background: rgba(0,0,0,.4);
    }
}

.overlay-1{
    &:before{
        background: rgba(11,60,93, .6);   
    }
    // &:hover:before{
    //     background: rgba(68, 132, 206, .6);   
    // }
}

.overlay-2{
    &:before{
        background: rgba(68, 132, 206, .6);   
    }
    // &:hover:before{
    //     background: rgba(11,60,93, .6);      
    // }
}

.overlay-1-hover{
    &:before{
        background: rgba(11,60,93, .6);   
    }
    &:hover:before{
        background: rgba(68, 132, 206, .6);   
    }
}

.overlay-2-hover{
    &:before{
        background: rgba(68, 132, 206, .6);   
    }
    &:hover:before{
        background: rgba(11,60,93, .6);      
    }
}

.overlay-1-dark{
    &:before{
        background: rgba(11,60,93, .85);   
    }
    // &:hover:before{
    //     background: rgba(68, 132, 206, .85);   
    // }
}

.overlay-2-dark{
    &:before{
        background: rgba($color-2, .25);   
    }
    // &:hover:before{
    //     background: rgba(11,60,93, .85);      
    // }
}

.center-divider{
    height: 3px; width: 80px; margin: 15px auto;
}

.bg-hover-1{
    transition: background-color .15s ease-in-out;    
    &:hover{
        background: $color-2 !important;
    }
}

.giant-padding{ 
    padding: 75px 0;
    @include breakpoint( tb ){
        padding: 175px 0;
    }
}

.overflow-hidden{
    overflow: hidden;
}

.home-scale{
    transition: all .15s ease-in-out;
    &:before{
         transition: background-color .15s ease-in-out;
        }
    &:hover{ 
        transform: scale(1.1);
        &:before{
            background-color: rgba(0,0,0,.6);
        }
    }
}

.dash-left{
    border-left: 2px solid $color-1;
    padding-left: 20px;
}

.heading-padding{
    padding-top: 150px;
    padding-bottom: 50px;
}

/* Margin Stuff */
.uk-margin-bottom{ margin-bottom: 15px; }
.uk-margin-top{ margin-top: 15px; }
.uk-margin-left{ margin-left: 15px; }
.uk-margin-right{ margin-right: 15px; }

.uk-margin-large-bottom{ margin-bottom: 50px; }
.uk-margin-large-top{ margin-top: 50px; }
.uk-margin-large-left{ margin-left: 50px; }
.uk-margin-large-right{ margin-right: 50px; }

.uk-margin-medium-bottom{ margin-bottom: 25px; }
.uk-margin-medium-top{ margin-top: 25px; }
.uk-margin-medium-left{ margin-left: 25px; }
.uk-margin-medium-right{ margin-right: 25px; }

.uk-grid-small {
    margin-left: -15px;
}
.uk-grid-medium {
    margin-left: -25px;
}

.uk-grid-large {
    margin-left: -50px;
}

.uk-grid-small>* {
    padding-left: 15px;
}
.uk-grid-medium>* {
    padding-left: 25px;
}
.uk-grid-large>* {
    padding-left: 50px;
}

.uk-margin-small-bottom{
    margin-bottom: 5px !important; 
}

.uk-block-medium{
    padding-top: 30px;
    padding-bottom: 30px;
}

.uk-block-extra-large{
    padding-top: 50px;
    padding-bottom: 50px;
    @include breakpoint( tb ){
        padding-top: 150px;
        padding-bottom: 150px;
    }
}

.z-front{
    z-index: 1;
    position: relative;
}

.uk-tab li.uk-active a.treatment-tab, .uk-tab li:hover a.treatment-tab{  color: #444 !important; } 
a.treatment-tab{ color: white !important; }

.raised-box.bottom-fixed{
    overflow-y: hidden;
    transition-property: all;
    transition-duration: .5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    max-height: 0;
    width: 100%;
    left: 0;
    right: 0;
    bottom: -20px;
    position: fixed;
    z-index: 99999999;    
}
.raised-box.bottom-fixed.visible-bar {    
    @include breakpoint( tb ){
        max-height: 120px;      
        bottom: 0;        
    }
    
}
.uk-offcanvas {    
    z-index: 999999999;
    background: rgba(0,0,0,.5);
}

.uk-modal{
    z-index: 111111111;
}