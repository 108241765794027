.typed-cursor{
    font-size: 28px;
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
        line-height: 42px;
    display: inline-block;
}
@keyframes blink{
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes blink{
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes blink{
  0% { opacity:1; }
  50% { opacity:0; }
  100% { opacity:1; }
}

.loved-one-container{
    border: 1px solid #222;
    border-radius: 10px;
    overflow: hidden;
    z-index: 0;
    position: relative;
    text-align: center;
    width: 200px;
    margin: 0 auto;
    span.or{
          z-index: 3;
          background-color: white;
          color: #222;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          -webkit-transform: translate(-50%,-50%);
          -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
          line-height: 24px;
          font-size: 12px;
          height: 24px;
          width: 24px;
      }
    ul{
      li{
        display: block;
        list-style: none;
        .me, .me-hover, .loved-one, .loved-one-hover{
            width: 100%;
            height: 53px;
            position: relative;
            z-index: -1;
            display: block;
            font-size: 20px;
            line-height: 50px;
            color: white;
            text-transform: uppercase;
            font-weight: bold;
        }
        .me{ background-color: $color-1; }
        .loved-one{ background-color: $color-2; }
        .loved-one-container .me-hover{
          background-color: white;
            color: $color-1;
        }
        .loved-one-container .loved-one-hover{
          background-color: white;
            color: $color-2;
        }        
      }
    }

    @include breakpoint( tb ){
      width: 402px;
      ul{
        font-size: 0;
        height: 53px;
        li{
          display: inline-block;
          width: 200px;
        }
      }
    }

}


.hover-container{
    height: 53px;
    position: relative;
    overflow: hidden;
}
.hover-inner{
    transform: translate3d(0px, 0px, 0px);
    transition: transform .2s ease-in-out;
}
.hover-container:hover{
    cursor: pointer;
}
.hover-container:hover .hover-inner.hover-me {
    transform: translate3d(0px, -53px, 0px);
}
.hover-container:hover .hover-inner.hover-lo {
    transform: translate3d(0px, 53px, 0px);
}

.service-thumb{
  position: relative;
  border: 2px solid black;
  .service-thumb-title{
        display: block;
    position: relative;
    // background: #fff;
    top: -30px;
    left: 50%;
    margin-left: -160px;
    padding: 10px;
    width: 320px;
    border: 2px solid #000;
    background: #be1e2d;
    color: #fff;
    text-align: center;
  }
  .service-thumb-text{
          position: relative;
    padding: 0px 15px 20px;
    margin-top: -18px;
    text-align: center;
  }
}
.circle-blurb-container{
  padding-top: 100px;
  .circle-blurb{
    border: $border-width solid $color-5;
    padding-top: 100px;
    .circle-icon{
      height: 202px;
      width: 202px;
      border-radius: 50%;
      padding: 20px;
      position: absolute;
      top: -100px;
      left: calc(50% - 101px);
      img {
        max-height: 110px;
        height: 100%;
        margin-top: 20px;
      }
    }
  }
}


.ohio-img-container{
  padding: 0 35px 0 0;
  position: relative;
  .large-img{
    position: relative;
    width: 100%;
  }
  .small-img{
    position: absolute;
    width: 45%;
    bottom: -20px;
    right: -35px;
  }
  .large-img img, .small-img img{
    width: 100%;
    border: 8px solid #fff;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
    -o-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
    -khtml-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -khtml-box-sizing: border-box;
  }
}


video#bgvideo {
    background: url(http://stodzydev.com/restore/wp-content/uploads/2017/02/poster.jpg) no-repeat;
    background-size: cover;
    display: none;
    height: auto;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    position: fixed;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    width: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    z-index: -100;
    /*background-image: url('/restore/wp-content/uploads/2017/01/Sky_Mountain-5-6.jpg');*/
}

.home-hero{
  padding-top: 50px;
  padding-bottom: 50px;
}
@media screen and (min-width: 720px){
  video#bgvideo { display: block; }
  .home-hero{
    padding-top: 150px;
    padding-bottom: 130px;
  }
}
.overlay-dark:before{
  background: rgba(0,0,0,.1);
}


.border-frame-3 {
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 500px;
    top: 20px;
    right: -20px;
    border: $border-width solid $color-3;
    z-index: 1;
}

.border-frame-3-left{
  right: 0;
  left: -20px;
}

.vid-container {
    height: 0;
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
    iframe{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
}

.bx-shadow-1 {
    box-shadow: 0 1px 9px rgba(0,0,0,.6), inset 0 0 40px rgba(0,0,0,.4);
}

.custom-border-circle{
  border: 5px solid $color-5;
  overflow: hidden;
}

.after-line:after{
      height: 5px;
    content: "";
    position: absolute;
    background: $color-5;
    display: block;
    width: 100%;
    top: 125px;
    z-index: 0;
}

/* Gallery Thumbnail Stuff */
.gallery-thumbnail {
    display: block;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 300px;
    position: relative;
    z-index: 1;
    color: white !important;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    font-size: 36px;
    box-sizing: border-box;
    line-height: 300px;
    font-weight: 400;
    transition: color .2s ease-in;
    margin-bottom: 20px;
    h3{
      display: inline-block;
      vertical-align: middle;
      line-height: 1em;
    }    
}



// Alphabar stuff
.alpha-bar {
    background-color: #eee;
    z-index: 100;
    float: left;
    width: 100%;
    overflow: hidden;
    /* border: 2px solid #f6bb09; */
    .alpha-letter {
      padding: 1em 0;
      margin: 0;
      font-size: 14px;
      display: inline-block;
      text-align: center;
      width: 3.8461538%;
      float: left;
      text-decoration: none!important;
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out;
    }
}

.single-letter:first-child{
  border-top: 1px solid #e9eff5;
}

.single-letter {
    background-color: #eee;
    //border-bottom: 2px solid #ccc;
    border-bottom: 1px solid #e9eff5;
}

.single-letter:nth-child(2n) {
    background-color: #fff;
}

.single-letter .letter {
    font-size: 48px;
    line-height: 48px;
    margin-top: 10px;
    display: block;
}

.cta-insurance {
    position: relative;
    z-index: 1;
    span{
      width: calc(100% - 260px);
      padding: 11px 0 0;
      img {
          margin: 0 24px;
      }
    }
}
.raised-box {
    display: block;
    margin: -55px auto 25px;
    padding: 8px 10px 10px;
    max-width: 350px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0,0,0,.3);
    @include breakpoint( ph ){
      margin: -30px auto 30px;
      padding: 10px;
      max-width: 550px;
    }
    @include breakpoint( tb ){
      margin: -35px auto 35px;
      max-width: 740px;
    }
    @include breakpoint( dk ){
      margin: -40px auto 40px;
      max-width: 900px;
    }
}

.block{
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    transition: all .2s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    &:hover {
      -ms-transform: scale(1.05);
      transform: scale(1.05);
    }
}

.more-text{ display: none; }

.state-container{
    max-width: 1040px;    
    margin: 0 auto;
}