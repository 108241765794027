// // Search form
// .search-form {
//   @extend .form-inline;
// }
// .search-form label {
//   font-weight: normal;
//   @extend .form-group;
// }
// .search-form .search-field {
//   @extend .form-control;
// }
// .search-form .search-submit {
//   @extend .btn;
//   @extend .btn-secondary;
// }

input, textarea, select{ width: 100%; }

.cool-contact{
	display: block;
    background: white;
    border-radius: 50px;
    position: relative;
    label{
        font-size: 12px;
	    display: inline-block;
	    position: relative;
	    top: 5px;
	    left: 30px;
        color: black;
    }
    input[type = "email"],input[type = "text"],textarea{
    	border: 0;
	    margin: 0 30px;
	    width: 80%;
	    outline: none;
	    font-size: 16px;
	    padding: 5px 0 10px 0;
	    background: none;
    }
    .ajax-loader{
    	position: absolute;
    }
    .wpcf7-submit{
    	    background-image: url(/wp-content/uploads/2017/06/arrow.png);
    border: 0;
    background-size: 20px 20px;
    position: absolute;
    top: -1px;
    background-position: 50%;
    right: 0;
    border-radius: 35px;
    width: 75px!important;
    height: 55px!important;
    background-repeat: no-repeat;
    background-color: transparent;
    text-indent: -100000em;
    padding: 0!important;
    font-size: 18px!important;   	
    }
    &:focus, &:active{
    	background-color: white;
    }
}


// Contact Form 7 Styling
.wpcf7-form .wpcf7-response-output.wpcf7-validation-errors {
    color: #D8000C;
    background-color: #FFBABA;
    border: 0;
    padding: 10px;
}
.wpcf7-form .wpcf7-response-output.wpcf7-mail-sent-ok {
    color: #4F8A10;
    background-color: #DFF2BF;
    border: 0;
    padding: 10px;
}
div.wpcf7-mail-sent-ok:before,div.wpcf7-validation-errors:before {
    font: 26px/30px FontAwesome;
    margin-right: 16px;
    vertical-align: middle;
}
div.wpcf7-mail-sent-ok:before {
    content:'\f00c';
}
div.wpcf7-validation-errors:before {
    content:'\f057';
}


#commentform input[type = "submit"]{
	// border-radius: 50px;
    background-color: transparent;
    text-decoration: none;
    padding: 10px 70px;
    font-size: 16px;
    background-color: $color-1;
    color: #fff;
    transition: all .15s ease-in-out;
    text-transform: uppercase;
    border: 0;
	&:hover{
		background-color: $color-2;
		color: white;
		cursor: pointer;
	}     

}